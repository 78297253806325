import React from "react";
import './CanaiaButtonWithIcon.scss';

interface CanaiaButtonWithIconProps {
    title?: string;
    image: string;
    alt?: string;
    className?: string;
    isSelected?: boolean;
    onClick?: () => void;
}

export const CanaiaButtonWithIcon: React.FC<CanaiaButtonWithIconProps> = ({title,image,alt, className,isSelected, onClick}) => {
    return (
        <div className={`canaia-button-with-image-wrapper ${className}  ${isSelected ? 'active' : ''} `} onClick={onClick} >
            <div className="image-wrapper">
                <img src={image} alt={alt} style={{width:"28px", margin:"0 auto"}} />
            </div>
            <div className="text-wrapper">
                {title}
            </div>
        </div>
    );
}