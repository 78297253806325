import {
    Header,
    HeaderGlobalBar,
    HeaderMenu,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
    HeaderPanel,
    Switcher,
    SwitcherItem,
    HeaderMenuButton, SideNav, SideNavItems, HeaderSideNavItems
} from "@carbon/react";
import CanaiaLogo from "../../atoms/CanaiaLogo/CanaiaLogo.tsx";
import {CanaiaButton} from "../../atoms/CanaiaButton/CanaiaButton.tsx";
import './AssistantHeader.scss'
import {useLogto} from "@logto/react";
import {Add, Launch, Logout, PricingConsumption, User} from '@carbon/icons-react';
import {useNavigate} from "react-router-dom";
import {authStore} from "../../../store/authStore.tsx";
import {CanaiaButtonWithIcon} from "../../atoms/CanaiaButtonWithIcon/CanaiaButtonWithIcon.tsx";
import {CanaiaMenuApps} from "../../components/CanaiaMenuApps/CanaiaMenuApps.tsx";
import IconAppsWhite from '../../../assets/images/icon-menu-apps-white.svg';
import IconAppsBlue from '../../../assets/images/icon-menu-apps-blue.svg';
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { useCheckAuth } from "../../../hooks/useCheckAuth.tsx";

interface Props {
    page?: string;
    onSelectSettings?: () => void;
}

export const AssistantHeader = ({page, onSelectSettings}: Props) => {
    const [showMenuApps, setShowMenuApps] = useState<string>('');
    const [showMenuAppsMobile, setShowMenuAppsMobile] = useState<string>('');
    const [isSideNavExpanded, setIsSideNavExpanded] = useState(false); // Estado para el menú lateral móvil
    const [isSwitcherOpen, setIsSwitcherOpen] = useState(false);
    const { t } = useTranslation();
    const { stateScope } = useCheckAuth();

    const menuRef = useRef<HTMLDivElement>(null); // Ref para el menú de escritorio
    const menuRef2 = useRef<HTMLDivElement>(null); // Ref para el menú móvil
    const buttonRef = useRef<HTMLDivElement>(null); // Ref para el botón

    const handleMenuApps = () => {
        setShowMenuApps(prev => (prev === 'visible' ? '' : 'visible'));
    };

    const handleMenuAppsMobile = () => {
        setShowMenuAppsMobile(prev => (prev === 'visible' ? '' : 'visible'));
    };

    const toggleSwitcher = () => {
        setIsSwitcherOpen(prev => !prev);
    };

    const onClickSideNavExpand = () => {
        setIsSideNavExpanded(prev => !prev); // Cambia el estado del menú lateral
    };

    const handleLogout =()=>{
        // Limpia la opción activa del localStorage
        localStorage.removeItem('activeMenuOption');
        // Cierra la sesión
        signOut(window.location.origin + "/");
    }

    useEffect(() => {
        // Definimos una función para manejar los clics fuera del menú
        const handleClickOutside = (event: MouseEvent) => {
            // Comprobamos si el menú está presente y si el clic fue fuera de él
            if (menuRef.current && !menuRef.current.contains(event.target as Node) &&
                buttonRef.current && !buttonRef.current.contains(event.target as Node)
            ) {
                // Si el clic fue fuera, cerramos el menú
                setShowMenuApps('');
            }
        };
        // Agregamos un evento de clic (mousedown) al documento
        document.addEventListener("mousedown", handleClickOutside);
        // Cleanup: quitamos el evento al desmontar el componente
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); // Este efecto depende de menuRef, así que se ejecutará cuando cambie

    useEffect(() => {
        const handleClickOutsideMobile = (event: MouseEvent) => {
            if (menuRef2.current && !menuRef2.current.contains(event.target as Node)) {
                setShowMenuAppsMobile('');
            }
        };
        document.addEventListener("mousedown", handleClickOutsideMobile);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMobile);
        };
    }, [menuRef2]);

    const getUrlIconAppsImage = () => {
        return page === 'white-header' ? IconAppsBlue : IconAppsWhite;
    };

    const {signOut} = useLogto();
    const {userName} = authStore.getState();
    const navigate = useNavigate();

    return (
        <>
            {/*Header para Desktop*/}
            <div className={'on-desktop'}>
                <Header className={`canaia-header ${page}`} aria-label={"CanaiaAssist"}>
                    <div className={'canaia-button-with-image-wrapper__global'} ref={buttonRef}>
                        <CanaiaButtonWithIcon image={getUrlIconAppsImage()} onClick={handleMenuApps} className={''}/>
                    </div>
                    <div className={`wrapper-menu-apps ${showMenuApps ? 'visible' : 'hidden'}`} ref={menuRef}>
                        <CanaiaMenuApps customClass={showMenuApps ? 'visible' : 'hidden'}/>
                    </div>
                    <HeaderName
                        className={'header-name'}
                        prefix={''}
                        isSideNavExpanded={false}
                        as={'a'}
                        style={{cursor: 'pointer'}}
                        onClick={() => navigate('/')}
                    >
                        <CanaiaLogo className={"logo-white"} type={"logo-white"}/>
                        <CanaiaLogo className={"logo-color"} type={"logo-color"}/>
                    </HeaderName>
                    <HeaderGlobalBar>
                        <CanaiaButton onClick={() => navigate('/choose-assistant')} kind={"secondary"}>
                            <Add size={28} className={"white-color"}/> {t('buttons.new_assistant')}
                        </CanaiaButton>
                        <HeaderNavigation aria-label="Canaia Assist">
                            <div className={"cds--header__submenu-icon"}>
                                <User/>
                            </div>
                            <HeaderMenu aria-label="Link 4" menuLinkName={userName}>
                                <HeaderMenuItem onClick={onSelectSettings} className={'cds--header__submenu-link submenu-settings'}>
                                    <Launch size={24} /> {t('menu.settings')}
                                </HeaderMenuItem>
                                <HeaderMenuItem
                                    onClick={() => window.location.href = 'https://staging.hub.canaia.ai/settings'}
                                    className={'cds--header__submenu-link submenu-pricing'}
                                >
                                    <Launch size={24} /> {t('menu.settings')}
                                </HeaderMenuItem>
                                {stateScope.some(scope => scope.includes('billing'))
                            && <HeaderMenuItem onClick={() => navigate('/subscription')} className={'cds--header__submenu-link submenu-pricing'}>
                                <PricingConsumption size={24} /> {t('menu.subscription')}
                            </HeaderMenuItem>}
                                <HeaderMenuItem onClick={handleLogout} className={'cds--header__submenu-link'}>
                                    <Logout size={24} />  {t('menu.logout')}
                                </HeaderMenuItem>
                            </HeaderMenu>
                        </HeaderNavigation>
                    </HeaderGlobalBar>
                </Header>
            </div>

            {/*Header para Mobile*/}
            <div className="on-mobile">
                <Header className={`canaia-header ${page}`} aria-label={"CanaiaAssist"}>
                    <HeaderMenuButton
                        aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
                        className={'header__close-btn'}
                        onClick={onClickSideNavExpand}
                        isActive={isSideNavExpanded}
                        aria-expanded={isSideNavExpanded}
                    />
                    <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false} onSideNavBlur={onClickSideNavExpand}>
                        <SideNavItems>
                            <HeaderSideNavItems>
                                <HeaderMenuItem href="#">Link 1</HeaderMenuItem>
                                <HeaderMenuItem href="#">Link 2</HeaderMenuItem>
                                <HeaderMenuItem href="#">Link 3</HeaderMenuItem>
                            </HeaderSideNavItems>
                        </SideNavItems>
                    </SideNav>
                    <CanaiaButtonWithIcon image={getUrlIconAppsImage()} onClick={handleMenuAppsMobile} className={''}/>
                    <div className={'canaia-button-with-buttons-wrapper'} ref={menuRef2}>
                        <CanaiaMenuApps customClass={showMenuAppsMobile}/>
                    </div>
                    <HeaderName
                        className={'header-name'}
                        prefix={''}
                        isSideNavExpanded={false}
                        as={'a'}
                        onClick={() => navigate('/')}
                    >
                        <CanaiaLogo className={"logo-white"} type={"logo-white"} />
                        <CanaiaLogo className={"logo-color"} type={"logo-color"} />
                    </HeaderName>
                    <HeaderGlobalBar>
                        <div className={'cds--header__submenu-icon right-panel'}>
                            <User onClick={toggleSwitcher} />
                        </div>
                    </HeaderGlobalBar>
                    <HeaderPanel className={'side-menu__left'} expanded={isSwitcherOpen}>
                        <Switcher aria-labelledby={''}>
                            <SwitcherItem aria-labelledby={''} href="#">Application 1</SwitcherItem>
                            <SwitcherItem aria-labelledby={''} href="#">Application 2</SwitcherItem>
                        </Switcher>
                    </HeaderPanel>
                </Header>
            </div>
        </>
    );
};
