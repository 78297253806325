import React, {useState} from 'react';
import './CanaiaMenuApps.scss';
import IconMaHub from '../../../assets/images/icon-ma-hub.svg';
import IconMaPlay from '../../../assets/images/icon-ma-play.png';
import IconMaAssist from '../../../assets/images/icon-ma-assist.svg';
import {CanaiaButtonWithIcon} from "../../atoms/CanaiaButtonWithIcon/CanaiaButtonWithIcon.tsx";
import {currentSite} from "../../../hooks/useGetAccessToken.tsx";
import {useNavigate} from "react-router-dom";

interface Props {
    customClass?:string;
}

export const CanaiaMenuApps : React.FC<Props> = ({customClass}) => {
    const [selectedApp, setSelectedApp] = useState<string |null>(currentSite);
    const navigate = useNavigate();

    const menuAppsOptions = {
        "Assist": {icon: IconMaAssist, href: "#"},
        "Hub": {icon: IconMaHub, href: import.meta.env.VITE_HUB_URL},
        "Play": {icon: IconMaPlay, href: import.meta.env.VITE_PLAY_URL},
        /*"Elevate": {icon: IconMaAssist, href: "https://www.google.com/"},
        "Talent": {icon: IconMaAssist, href: "https://www.youtube.com/"},
        "Collect": {icon: IconMaCollect, href: "https://www.google.com/"},
        "Ad": {icon: IconMaCollect, href: "https://www.youtube.com/"}*/
    }

    const handleMenuAppClick = (key: string,href:string) =>{
        setSelectedApp(key);
        if(key === 'Assist') {
            navigate('/');
        }
        window.location.href = href;
    }

    return (
        <div className={`canaia-menu-apps-wrapper ${customClass}`}>
            <div className="canaia-menu-apps-inner">
                {Object.entries(menuAppsOptions).map(([key, {icon,href}]) => (
                    <CanaiaButtonWithIcon
                        key={key}
                        title={key}
                        image={icon}
                        onClick={()=> handleMenuAppClick(key,href)}
                        isSelected={selectedApp===key}
                        className={''}
                    />
                ))}
            </div>
        </div>
    )
}